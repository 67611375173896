<template>
  <div>
    <h3>{{ name }}</h3>
    <div class="container">
      <iframe
        :src="gg"
        height="250"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
      <div class="grid">
        <img :src="images.tel" alt="telephone" />
        <p>
          <a :href="'tel:' + tel1">{{ tel1 }}</a>
          ,
          <a :href="'tel:' + tel2">{{ tel2 }}</a>
        </p>
        <img :src="images.email" alt="email" />
        <p>
          <a :href="'mailto:' + mail">{{ mail }}</a>
        </p>
        <img :src="images.address" alt="address" />
        <p>{{ address }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { storage } from "../../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";

export default {
  props: ["name", "address", "gg"],
  computed: {
    tel1() {
      return this.$t("contact.tel1");
    },
    tel2() {
      return this.$t("contact.tel2");
    },
    mail() {
      return this.$t("contact.mail");
    },
  },
  data() {
    return {
      images: {
        tel: "",
        email: "",
        address: "",
        map: "",
      },
    };
  },
  created() {
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(storage, `contact/${key}.png`)).then((url) => {
        this.images[key] = url;
      })
    );
  },
};
</script>

<style scoped>
.grid {
  display: inline-grid;
  grid-template-columns: 13% 87%;
  text-align: left;
}

img {
  height: 2.2rem;
}

.container {
  display: flex;
  width: 80%;
  font-size: 17px;
  margin-left: 10%;
}

img {
  margin: auto;
}

p {
  margin: auto;
  margin-left: 0;
}

h3 {
  position: relative;
  text-align: left;
  left: 10%;
}

a {
  color: #2c3e50;
}

iframe {
  width: 50%;
}

@media screen and (max-width: 780px) {
  .container {
    display: block;
    font-size: 13px;
  }

  img {
    height: 1.8rem;
  }

  h3 {
    text-align: center;
    position: inherit;
  }

  iframe {
    width: 100%;
  }
}
</style>
