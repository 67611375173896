<template>
  <div>
    <h2>{{ $t("contact.form.fill") }}</h2>
    <b-form @submit.prevent="sendEmail" ref="form">
      <b-form-group>
        <label for="from_name">{{ $t("contact.form.name") }}*</label>
        <b-form-input
          name="from_name"
          v-model="from_name"
          required
        ></b-form-input>
        <b-form-invalid-feedback />
      </b-form-group>
      <b-form-group>
        <label for="email">{{ $t("contact.form.email") }}*</label>
        <b-form-input
          name="email"
          type="email"
          v-model="email"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label for="phone">{{ $t("contact.form.tel") }}</label>
        <b-form-input name="phone" v-model="phone" type="number"></b-form-input>
      </b-form-group>
      <div class="msg">
        <label for="message">{{ $t("contact.form.message") }}*</label>
        <b-form-textarea
          name="message"
          v-model="message"
          required
        ></b-form-textarea>
      </div>
      <br />
      <b-button type="submit">{{ $t("contact.form.submit") }}</b-button>
    </b-form>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  data() {
    return { from_name: "", email: "", phone: "", message: "" };
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_ytd2h0d",
          "template_guioo4h",
          this.$refs.form,
          "user_d5W7bB8EAC7FmY8ClKv5Z"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.from_name = "";
            this.reply_to = "";
            this.phone = "";
            this.message = "";
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
  },
};
</script>

<style scoped>
label {
  font-size: larger;
  width: 150px;
  padding: 8px;
  /* margin-left: 250px; */
}
input {
  width: 65%;
  display: inline;
}
div {
  text-align: left;
}
h2 {
  text-align: center;
}
.msg {
  display: grid;
  grid-template-columns: 150px 65%;
}
button {
  color: white;
  background-color: #2a3e47;
  margin-left: 150px;
  width: 80px;
  border: none;
  font-size: larger;
  padding: 2px;
  /* border-radius: 0%; */
}
textarea {
  height: 200px;
}
form {
  width: 60%;
  margin: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media screen and (max-width: 1200px) {
  .msg {
    display: block;
  }
  input {
    width: 100%;
  }
  button {
    margin: 0%;
  }
  form {
    width: 90%;
  }
}
</style>
