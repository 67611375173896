<template>
  <div>
    <h1 class="mt-4">{{ $t("contact.name") }}</h1>
    <Address
      class="mt-5 mb-5"
      :name="$t('contact.card1.name')"
      :address="$t('contact.card1.address')"
      :gg="$t('contact.card1.gg')"
    />
    <Address
      class="mt-5 mb-5"
      :name="$t('contact.card2.name')"
      :address="$t('contact.card2.address')"
      :gg="$t('contact.card2.gg')"
    />
    <br />
    <Form class="mt-5 mb-5" />
  </div>
</template>

<script>
import Address from "@/components/contact/Address.vue";
import Form from "@/components/contact/Form.vue";
import { storage } from "../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
export default {
  components: { Address, Form },
  data() {
    return {
      building: "",
    };
  },
  created() {
    getDownloadURL(ref(storage, `contact/building.png`)).then((url) => {
      this.building = url;
    });
  },
};
</script>

<style scoped>
div {
  overflow: hidden;
}
img {
  width: 90%;
}
iframe {
  width: 90%;
}
@media screen and (max-width: 780px) {
  .map h3 {
    margin-left: 4%;
  }
}
</style>
